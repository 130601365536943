import de from '../intl/de'
import en from '../intl/en'
import el from '../intl/el'
import fr from '../intl/fr'

const LOCALE = {
  de: de,
  en: en,
  el: el,
  fr: fr
}
   
export default LOCALE