import LOCALE from '../intl'

export const defaultLocale = LOCALE.en

export const locales = LOCALE
const isServerSide = () => !(typeof window !== 'undefined' && window !== null)

const getLocaleFromBrowser = () => {
  for (const [key, value] of Object.entries(locales)) {
    if( !isServerSide() && navigator.language.indexOf(key) > -1 ){
      return value
    }
  }
  return null
} 
export const getInitLanguage = () =>{
  const browserLang = getLocaleFromBrowser()
 
  const localeStorageLocale = !isServerSide() && localStorage.getItem('locale') 
  return locales[localeStorageLocale] ? locales[localeStorageLocale] : browserLang ? browserLang : defaultLocale
}

export const setLocaleObject = (locale='en', setLocaleFunc) =>{
  const currentLocale = LOCALE[locale] ? LOCALE[locale] : defaultLocale
  !isServerSide() && setLocaleFunc(currentLocale)
  !isServerSide() && (document.documentElement.lang = currentLocale.language)
  !isServerSide() && localStorage.setItem('locale', currentLocale.language)
}
