import * as React from 'react'

export const FooterColumn = ({title, items}) => {
  return (
    <div className='contact__info'>
      <h3 className='contact__info-title'>{title}</h3>
      {items.map((item, index )=> (
        <p key={index}> 
          {item.icon} 
          <a target='_blank' href={item.link}> {item.linkText}</a>
        </p> 
      ))}
    </div>
  )
}