import * as React from 'react'
import { VscGlobe } from 'react-icons/vsc'
import Logo from '../assets/logo-tile.svg'
import { GoLocation } from 'react-icons/go'
import { FaTripadvisor, FaGoogle } from 'react-icons/fa'
import {IoCloseSharp, IoMenuOutline} from 'react-icons/io5'
import { setLocaleObject } from '../services/i18n.service'

export const Header = ({setIsMenuOpen, isMenuOpen, locale, setLocale})=>{
  React.useEffect(()=>{
    setLocaleObject(locale.language, setLocale)
  }, []) 
 
  return ( <header>
    <div>
      <div className='quick-links--sticky animation--slide-from-right'>
        <a className='link__image--medium my--m' 
          // eslint-disable-next-line max-len
          href='https://www.tripadvisor.de/Hotel_Review-g189434-d6725863-Reviews-Margarita_Sifnos_Rooms_and_Studios-Sifnos_Cyclades_South_Aegean.html?m=19905' 
          target='_blank'>
          <FaTripadvisor size={35} />
        </a>
        <a className='link__image--medium my--m' 
          href='https://www.google.com/travel/hotels/s/PSFro' target='_blank'>
          <FaGoogle size={35} />
        </a>
        <a className='link__image--medium my--m' 
          // eslint-disable-next-line max-len
          href='https://www.google.com/maps/place/Margarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece/@36.9688419,24.7217233,17z/data=!4m6!1m3!3m2!1s0x1498f2ac200f57b5:0xd8da8fac8049c726!2sMargarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece!3m1!1s0x1498f2ac200f57b5:0xd8da8fac8049c726'
          target='_blank'>
          <GoLocation size={35} />
        </a>
      </div>
      <div className='flex flex-center'>
        <div className='header__wrapper'>
          <div className='header__logo-wrapper'>
            <a href='/'><Logo className='header__logo'/></a>
            <div className={`header__nav-wrapper--${isMenuOpen ? 'open' : 'closed'}`}>
              <nav className='header__nav'>
                <div className='header__nav-container'>
                  <ul className='header__nav-list'>
                    <li className='active'>
                      <a href='/#overview' onClick={() => { setIsMenuOpen(false)}}>{locale.rooms}</a>
                    </li>
                    <li>
                      <a href='/#reviews' onClick={() => { setIsMenuOpen(false)}}>{locale.impressions}</a>
                    </li>
                    <li>
                      <a href='/#location' onClick={() => { setIsMenuOpen(false)}}>{locale.location}</a>
                    </li>
                    <li>
                      <a href='/#contact' onClick={() => { setIsMenuOpen(false)}}>{locale.contact}</a>
                    </li>
                    <li className='lg_hidden'>
                      <a target='_blank' onClick={() => { setIsMenuOpen(false)}}
                        // eslint-disable-next-line max-len
                        href='https://www.tripadvisor.de/Hotel_Review-g189434-d6725863-Reviews-Margarita_Karidi_Rooms_and_Studios-Sifnos_Cyclades_South_Aegean.html?m=19905' >
                        Trip Advisor
                      </a>
                    </li>
                    <li className='lg_hidden'>
                      <a href='https://www.google.com/travel/hotels/s/PSFro' 
                        target='_blank' onClick={() => { setIsMenuOpen(false)}}>
                        Google business
                      </a>
                    </li>
                    <li className='lg_hidden'>
                      <a target='_blank' onClick={() => { setIsMenuOpen(false)}}
                        // eslint-disable-next-line max-len
                        href='https://www.google.com/maps/place/Margarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece/@36.9688419,24.7217233,17z/data=!4m6!1m3!3m2!1s0x1498f2ac200f57b5:0xd8da8fac8049c726!2sMargarita+Sifnos+Rooms+%26+Studios,+Sifnos+840+03,+Greece!3m1!1s0x1498f2ac200f57b5:0xd8da8fac8049c726' 
                      >
                      Google Maps {locale.location}
                      </a>
                    </li>
                  </ul>
                  <div className='header__nav-lang-select'>
                    <VscGlobe className='header__nav-lang-select-logo' />
                    <select value={locale.language} onChange={e => setLocaleObject(e.target.value, setLocale)}>
                      <option value='en'>English</option>
                      <option value='el'>Ελληνικα</option>
                      <option value='fr'>Francais</option>
                      <option value='de'>Deutsch</option>
                    </select>
                  </div>
                </div>
                <IoCloseSharp size={50} className='header__nav-close' onClick={() => { setIsMenuOpen(false) }} />
              </nav>
            </div>
            <IoMenuOutline size={55} onClick={() => {
              setIsMenuOpen(!isMenuOpen)
            }} className='header__hamburger-menu-icon lg_hidden' />
          </div>
        </div>
      </div>
    </div>
    <div className='clear'></div>
  </header>)
}