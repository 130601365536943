import * as React from 'react'
import {FooterColumn} from '../components/FooterColumn'
import {BiEnvelope} from 'react-icons/bi'
import {FiPhone} from 'react-icons/fi'
import {GiShipWheel, GiSailboat} from 'react-icons/gi'
import {AiOutlineCar} from 'react-icons/ai'
import {VscDeviceMobile, VscLocation} from 'react-icons/vsc'
import {RiCalendarEventLine, RiBusFill} from 'react-icons/ri'
import {IoTrailSignOutline, IoAirplaneOutline} from 'react-icons/io5'
import {getInitLanguage} from '../services/i18n.service'

export const Footer = () => {
  const locale = getInitLanguage()

  const useFullLinks = [
    {link:'https://www.directferries.com/', 
      icon:<GiShipWheel className='header__contact-info-icon' /> , 
      linkText: locale.ferries},
    {link:'https://sifnos.e-sifnos.com/plan-trip-to-sifnos/sifnos-taxi.php', 
      icon:<RiBusFill className='header__contact-info-icon' /> ,
      linkText: locale.transportation},
    {link:'https://www.aia.gr/', 
      icon:<IoAirplaneOutline className='header__contact-info-icon' /> , 
      linkText: locale.airport},
    {link:'https://sifnos.e-sifnos.com/plan-trip-to-sifnos/sifnos-rent-car-bike.php', 
      icon:<AiOutlineCar className='header__contact-info-icon' /> , 
      linkText: locale.rentals}
  ]

  const suggestions = [
    {link:'https://sifnostrails.com/',
      icon:<IoTrailSignOutline className='header__contact-info-icon' /> ,
      linkText:locale.sifnos_trails},
    {link:'http://sifnos2day.gr/', 
      icon:<RiCalendarEventLine className='header__contact-info-icon' /> , 
      linkText: locale.events_sifnos},
    {link:'https://www.roomsinsifnos.com/el/drastiriotites/krouazieres-enoikiaseis-skafon-alieftikes-ekdromes', 
      icon:<GiSailboat className='header__contact-info-icon' /> , 
      linkText: locale.cruises}
  ]

  const contactDetails = [
    {link:'#location', 
      icon:<VscLocation className='header__contact-info-icon' /> , 
      linkText: locale.address},
    {link:'mailto:mkaridi@gmail.com?subject=Mail from margaritasifnos.gr', 
      icon:<BiEnvelope className='header__contact-info-icon' /> , 
      linkText:'mkaridi@gmail.com'},
    {link:'tel:+302284032013', 
      icon:<FiPhone className='header__contact-info-icon' /> ,
      linkText:'(+30) 22840-32013'},
    {link:'tel:+306949908072', 
      icon:<VscDeviceMobile className='header__contact-info-icon' /> , 
      linkText:'(+30) 69499-08072'}
  ]
  return (
    <>
      <div className='footer__columns-wrapper'>
        <FooterColumn title={locale.contact_details} items={contactDetails}></FooterColumn>
        <FooterColumn title={locale.we_suggest} items={suggestions}></FooterColumn>
        <FooterColumn title={locale.useful_links} items={useFullLinks}></FooterColumn>
      </div>
      <div className='bottom__footer'>
        <div>© Margarita Sifnos | Rooms & Studios 2021</div> 
        <div>Developed by yianna  </div>
      </div>
    </>
  )
}